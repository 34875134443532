import React from "react"
import { Link, StaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import SocialMedia from "./social-media"
//import CloseButton from "../images/close-button.svg"

const NavBar = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          strapiGlobal {
            ...StrapiGlobal
          }
          allStrapiCategory {
            edges {
              node {
                slug
                name
              }
            }
          }
          allStrapiMainNavigation {
            nodes {
              body {
                ... on STRAPI__COMPONENT_MENU_LINK {
                  name
                  url
                  icon
                  style
                }
                ... on STRAPI__COMPONENT_MENU_DROPDOWN {
                  link {
                    name
                    url
                    icon
                    style
                  }
                  sections {
                    label
                    icon
                    links {
                      name
                      url
                      style
                      isInternal
                    }
                  }
                }
              }
              socialMediaLink {
                name
                url
                style
                icon
              }
            }
          }
          allStrapiOffCanvasNavigation {
            nodes {
              body {
                ... on STRAPI__COMPONENT_MENU_LINK {
                  name
                  url
                  style
                  icon
                }
                ... on STRAPI__COMPONENT_MENU_DROPDOWN {
                  link {
                    name
                    url
                    style
                    icon
                  }
                  sections {
                    label
                    icon
                    links {
                      name
                      url
                      style
                    }
                  }
                }
              }
              socialMediaLink {
                name
                url
                style
                icon
              }
            }
          }
        }
      `}
      render={data => {
        // 2 hands - map image
        //const logo = data.strapiGlobal.defaultSeo.shareImage.localFile

        // paw logo - favicon
        const logo = data.strapiGlobal.favicon.localFile

        const navigation = data.allStrapiMainNavigation.nodes[0]
        const socialMediaLinks = navigation.socialMediaLink
        //console.log("Navigation: ", navigation)
        //console.log("SocialMediaLinks: ", socialMediaLinks)
        //console.log("Body: ", navigation.body[0].name)

        const offCanvasNvigation = data.allStrapiOffCanvasNavigation.nodes[0]
        const offCanvasSocialMediaLinks = offCanvasNvigation.socialMediaLink
        //console.log("offCanvasNvigation: ", offCanvasNvigation)
        //console.log("offCanvasSocialMediaLinks: ", offCanvasSocialMediaLinks)
        //console.log("off canvas Body: ", offCanvasNvigation.body[0].name)

        const activeStyles = {
          //color: "#617F7F",
          color: "#ff7f00",
          fontWeight: 600,
          //background: "#f6f7f9",
          borderBottom: "2px solid #617F7F",
        }

        const offCanvasActiveStyles = {
          color: "#617F7F",
          fontWeight: 700,
        }

        return (
          <header
            className="page-header uk-box-shadow-medium uk-background-secondary uk-padding-remove"
            uk-sticky="animation: uk-animation-slide-top; sel-target: .uk-navbar-container; cls-active: uk-navbar-sticky; cls-inactive: uk-navbar-transparent uk-dark; top: 200"
            //style={{ backgroundColor: "#000000"}}
          >
            <div className="uk-container uk-container-expand uk-height-1-1">
              <nav className="uk-height-1-1 uk-padding-remove" uk-navbar="true">
                <Link to="/" className="uk-flex uk-flex-middle">
                  <GatsbyImage
                    image={getImage(logo)}
                    alt={data.strapiGlobal.favicon.alternativeText}
                  />
                </Link>

                <ul className="uk-navbar-nav uk-navbar-center uk-padding-remove uk-visible@l">
                  {navigation.body.map((item, index) => {
                    //console.log("Item: ", item)
                    let name = item.name

                    //let link = item.link
                    //let sections = item.sections
                    //console.log("Name: ", name)
                    //console.log("link: ", link)
                    //console.log("link - name: ", link?.name)
                    //console.log("sections: ", sections)

                    return name ? (
                      <li key={index}>
                        <Link
                          className="hover-underline-animation"
                          to={item.url}
                          activeStyle={activeStyles}
                          //style={{ color: "#FFFFFF" }}
                        >
                          {item.name}
                        </Link>
                      </li>
                    ) : (
                      <li key={index} className="multi">
                        <Link
                          className="hover-underline-animation"
                          to={item?.link?.url}
                          activeStyle={activeStyles}
                          //style={{ color: "#FFFFFF" }}
                        >
                          {item?.link?.name}
                          <span uk-icon="icon: chevron-down"></span>
                        </Link>
                        <div
                          //className="uk-navbar-dropdown uk-navbar-dropdown-width-3"
                          className={
                            item.sections.length === 1
                              ? "uk-navbar-dropdown uk-width-1-3"
                              : "uk-navbar-dropdown uk-navbar-dropdown-width-3"
                          }
                          uk-dropdown="pos: bottom-justify; offset: 10; animation: reveal-top; animate-out: true; duration: 700"
                          uk-toggle="true"
                          style={{ borderRadius: "10px" }}
                        >
                          <ul
                            className={
                              item.sections.length === 1
                                ? "uk-nav uk-navbar-dropdown-nav uk-column-1-1"
                                : "uk-nav uk-navbar-dropdown-nav uk-column-1-2"
                            }
                          >
                            {item?.sections.map((section, index) => {
                              //console.log("Section: ", section)
                              return (
                                <li key={index}>
                                  <span uk-icon={section.icon}></span>

                                  <ul className="uk-nav uk-navbar-dropdown-nav uk-margin-top">
                                    <li className="uk-nav-header uk-margin-small-bottom uk-text-bold">
                                      {section.label}
                                    </li>
                                    {section?.links?.map((item, index) => {
                                      //console.log("Item: ", item)

                                      return (
                                        <li key={index}>
                                          {item.isInternal ? (
                                            <Link
                                              to={item.url}
                                              className="hover-underline-animation"
                                              activeStyle={activeStyles}
                                            >
                                              {item.name}
                                            </Link>
                                          ) : (
                                            <a
                                              href={item.url}
                                              target="_blank"
                                              rel="noreferrer"
                                              className="hover-underline-animation"
                                              //activeStyle={activeStyles}
                                            >
                                              {item.name}
                                            </a>
                                          )}
                                        </li>
                                      )
                                    })}
                                  </ul>
                                </li>
                              )
                            })}
                          </ul>
                        </div>
                      </li>
                    )
                  })}
                </ul>

                <ul className="uk-navbar-nav uk-navbar-center uk-padding-remove uk-hidden@l">
                  <li>
                    <SocialMedia
                      socialMediaLinks={socialMediaLinks}
                      className="hover-underline-animation"
                    />
                  </li>
                </ul>
                <div className="uk-navbar-right">
                  <div className="uk-visible@l">
                    <SocialMedia
                      socialMediaLinks={socialMediaLinks}
                      className="hover-underline-animation"
                    />
                  </div>

                  <button
                    className="uk-margin-left uk-hidden@l"
                    uk-toggle="target: #offcanvas"
                    uk-icon="icon: menu; ratio: 2"
                    type="button"
                    aria-label="Toggle Mobile Menu"
                    style={{ color: "#FFFFFF" }}
                  ></button>
                </div>
              </nav>
            </div>

            <div
              className="offcanvas uk-hidden@l"
              id="offcanvas"
              uk-toggle="true"
              uk-offcanvas="flip: true; overlay: true; mode: reveal"
            >
              <div className="uk-offcanvas-bar">
                <button
                  className="uk-offcanvas-close uk-margin-small-top"
                  type="button"
                  uk-icon="icon: close; ratio: 2"
                  //uk-close="true"
                  aria-label="Close Mobile Menu"
                ></button>

                <ul className="top-menu uk-nav uk-margin-top">
                  {offCanvasNvigation.body.map((item, index) => {
                    //console.log("Item: ", item)
                    let name = item.name

                    //let link = item.link
                    //let sections = item.sections
                    //console.log("Name: ", name)
                    //console.log("link: ", link)
                    //console.log("link - name: ", link?.name)
                    //console.log("sections: ", sections)

                    return name ? (
                      <li key={index}>
                        <Link
                          className="hover-underline-animation"
                          to={item.url}
                          activeStyle={offCanvasActiveStyles}
                        >
                          {item?.icon && (
                            <span
                              className="uk-margin-small-right"
                              uk-icon={item?.icon}
                            ></span>
                          )}
                          {item.name}
                        </Link>
                      </li>
                    ) : (
                      <li key={index}>
                        <Link
                          to={item?.link?.url}
                          className="hover-underline-animation"
                          activeStyle={offCanvasActiveStyles}
                        >
                          {item?.link?.icon && (
                            <span
                              className="uk-margin-small-right"
                              uk-icon={item?.link?.icon}
                            ></span>
                          )}
                          {item?.link?.name}
                        </Link>

                        {item?.sections?.map((section, index) => {
                          //console.log("Section: ", section)
                          return (
                            <ul key={index} className="sub-menu uk-nav">
                              <li className="uk-nav-header uk-text-bold gradient-offcanvas-title">
                                {section.label}
                              </li>
                              {section?.links?.map((item, index) => {
                                //console.log("Item: ", item)

                                return (
                                  <li key={index}>
                                    <Link
                                      to={item.url}
                                      className="hover-underline-animation"
                                      activeStyle={offCanvasActiveStyles}
                                    >
                                      {item.name}
                                    </Link>
                                  </li>
                                )
                              })}
                            </ul>
                          )
                        })}
                      </li>
                    )
                  })}
                </ul>
                <div className="uk-flex uk-flex-center uk-margin-small-top uk-margin-small-bottom ">
                  <SocialMedia socialMediaLinks={offCanvasSocialMediaLinks} />
                  {/* <AdminLinks /> */}
                </div>
              </div>
            </div>
          </header>
        )
      }}
    />
  )
}

export default NavBar
